import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { fleetMemberStepFiveInitialValues } from "../../../../../../utils/formik/initialvalue";
import { fleetMemberStepFiveValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  SectionTitle,
} from "../../../../../styledComponent";
import { normalizeInput } from "../../../../../../utils/helpers";
import { POST_API_URLS } from "../../../../../../utils/apiConstant";
import { commonPostApiHandler } from "../../../../../../api/commonApiCall";
import styles from "../../style.module.scss";

const StepFive = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const formikRef = useRef(null);
  const formik = useFormik({
    initialValues: fleetMemberStepFiveInitialValues,
    validationSchema: fleetMemberStepFiveValidationSchema,
    onSubmit: (values) => formSubmitHandler(values),
  });

  async function formSubmitHandler(data) {
    props.isSubmitting(true);
    const formRes = await commonPostApiHandler(
      POST_API_URLS.FLEET_CARD_MEMBER_FORM,
      {
        ...props.prevData,
        banking_information: data,
      }
    );
    formRes && formRes.response && props.isSuccess(true);
    props.isSubmitting(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <SectionTitle className={styles.formTitle}>
        Banking Information
      </SectionTitle>
      <div>
        <div>
          <Label htmlFor="financial_institution">Financial Institution*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("financial_institution")}
            style={
              formik.touched.financial_institution &&
              formik.errors.financial_institution && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.financial_institution &&
            formik.errors.financial_institution && (
              <ErrorText className={styles.errorText}>
                {formik.errors.financial_institution}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="branch">Branch*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("branch")}
            style={
              formik.touched.branch &&
              formik.errors.branch && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.branch && formik.errors.branch && (
            <ErrorText className={styles.errorText}>
              {formik.errors.branch}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="street">Street*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("street")}
            style={
              formik.touched.street &&
              formik.errors.street && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.street && formik.errors.street && (
            <ErrorText className={styles.errorText}>
              {formik.errors.street}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="apartment">Apt*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("apartment")}
            style={
              formik.touched.apartment &&
              formik.errors.apartment && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.apartment && formik.errors.apartment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.apartment}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="city">City*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("city")}
            style={
              formik.touched.city &&
              formik.errors.city && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.city && formik.errors.city && (
            <ErrorText className={styles.errorText}>
              {formik.errors.city}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="province">Province*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("province")}
            style={
              formik.touched.province &&
              formik.errors.province && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.province && formik.errors.province && (
            <ErrorText className={styles.errorText}>
              {formik.errors.province}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="first_name">First Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("first_name")}
            style={
              formik.touched.first_name &&
              formik.errors.first_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.first_name}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="last_name">Last Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("last_name")}
            style={
              formik.touched.last_name &&
              formik.errors.last_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.last_name}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="phone">Phone*</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
            }}
            value={phoneNumber}
            style={
              formik.touched.phone &&
              formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.phone && formik.errors.phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.phone}
            </ErrorText>
          )}
        </div>
      </div>
      <div className={styles.formBtnContainer}>
        <Button type="submit">Submit</Button>
      </div>
    </Form>
  );
};

export default StepFive;
