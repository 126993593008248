import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { fleetMemberStepTwoInitialValues } from "../../../../../../utils/formik/initialvalue";
import { fleetMemberStepTwoValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
} from "../../../../../styledComponent";
import { normalizeInput } from "../../../../../../utils/helpers";
import styles from "../../style.module.scss";
import { ChevronRight } from "react-bootstrap-icons";

const StepTwo = (props) => {
  const [businessPhone, setBusinessPhone] = useState("");
  const [cell, setCell] = useState("");
  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: fleetMemberStepTwoInitialValues,
    validationSchema: fleetMemberStepTwoValidationSchema,
    onSubmit: (values) => {
      props.next();
      props.data(values);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("business_phone", businessPhone);
    }, 1000);

    return () => clearTimeout(timer);
  }, [businessPhone, formik]);

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("cell", cell);
    }, 1000);

    return () => clearTimeout(timer);
  }, [cell, formik]);

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <div>
        <div>
          <Label htmlFor="street">Street*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("street")}
            style={
              formik.touched.street &&
              formik.errors.street && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.street && formik.errors.street && (
            <ErrorText className={styles.errorText}>
              {formik.errors.street}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="apartment">Apt.*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("apartment")}
            style={
              formik.touched.apartment &&
              formik.errors.apartment && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.apartment && formik.errors.apartment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.apartment}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="city">City*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("city")}
            style={
              formik.touched.city &&
              formik.errors.city && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.city && formik.errors.city && (
            <ErrorText className={styles.errorText}>
              {formik.errors.city}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="province">Province*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("province")}
            style={
              formik.touched.province &&
              formik.errors.province && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.province && formik.errors.province && (
            <ErrorText className={styles.errorText}>
              {formik.errors.province}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="postcode">Postal Code*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("postcode")}
            style={
              formik.touched.postcode &&
              formik.errors.postcode && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.postcode && formik.errors.postcode && (
            <ErrorText className={styles.errorText}>
              {formik.errors.postcode}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="email">Email*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("email")}
            style={
              formik.touched.email &&
              formik.errors.email && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorText className={styles.errorText}>
              {formik.errors.email}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="business_phone">Bus. Phone*</Label>
          <Input
            type="text"
            name="business_phone"
            id="business_phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setBusinessPhone((prev) => normalizeInput(e.target.value, prev));
            }}
            value={businessPhone}
            style={
              formik.touched.business_phone &&
              formik.errors.business_phone && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.business_phone && formik.errors.business_phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.business_phone}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="cell">Cell*</Label>
          <Input
            type="text"
            name="cell"
            id="cell"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setCell((prev) => normalizeInput(e.target.value, prev));
            }}
            value={cell}
            style={
              formik.touched.cell &&
              formik.errors.cell && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.cell && formik.errors.cell && (
            <ErrorText className={styles.errorText}>
              {formik.errors.cell}
            </ErrorText>
          )}
        </div>
      </div>
      <div className={styles.formBtnContainer}>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepTwo;
