import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Anchor, SectionTitle, Text } from "../../components/styledComponent";
import fleetCardImg from "../../assets/fleetCard.png";
import benefitOne from "../../assets/benefitOne.png";
import benefitTwo from "../../assets/benefitTwo.png";
import benefitThree from "../../assets/benefitThree.png";
import benefitFour from "../../assets/benefitFour.png";
import styles from "./style.module.scss";
import { routes } from "../../utils/routes";

const CancoFleetCard = () => {
  return (
    <div className={styles.fleetCardPage}>
      {/* Banner section */}
      <div className={styles.bannerSection}></div>

      {/* Benefits of fleet card */}
      <Container className={`sectionContainer ${styles.benefitsFleetCard}`}>
        <Row className={styles.benefitsTitle}>
          <Col>
            <SectionTitle>A fleet card that works for you!</SectionTitle>
          </Col>
        </Row>
        <Row className={styles.benefitsContent}>
          <Col xs={12} md={12} lg={6} className={styles.benefitInfo}>
            <SectionTitle>Benefits</SectionTitle>
            <div className={styles.benefitBlock}>
              <div>
                <img src={benefitOne} alt="benefit" />
              </div>
              <div>
                <ul>
                  <li>Annual Fee: $0</li>
                  <li>Tiered Volume Discounts</li>
                  <li>5% Discount on Automotive Purchases</li>
                </ul>
              </div>
            </div>
            <div className={styles.benefitBlock}>
              <div>
                <img src={benefitTwo} alt="benefit" />
              </div>
              <div>
                <ul>
                  <li>Dedicated Customer Service</li>
                  <li>Pin Protected</li>
                  <li>Spending Control</li>
                </ul>
              </div>
            </div>
            <div className={styles.benefitBlock}>
              <div>
                <img src={benefitThree} alt="benefit" />
              </div>
              <div>
                <ul>
                  <li>Easy to Manage</li>
                  <li>Detailed Card Reporting</li>
                </ul>
              </div>
            </div>
            <div className={styles.benefitBlock}>
              <div>
                <img src={benefitFour} alt="benefit" />
              </div>
              <div>
                <ul>
                  <li>
                    High quality fuel purchased from same terminals as major
                    national brands
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <img src={fleetCardImg} alt="Fleet card" />
          </Col>
        </Row>
      </Container>

      {/* Apply Today */}
      <div className={styles.applyTodaySection}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col>
              <SectionTitle>Apply today in 3 easy steps</SectionTitle>
              <div className={styles.applySteps}>
                <div className={styles.stepInfo}>
                  <h3>1</h3>
                  <Text>Complete the Application</Text>
                </div>
                <div className={styles.stepInfo}>
                  <h3>2</h3>
                  <Text>Send Application and Void Cheque</Text>
                </div>
                <div className={styles.stepInfo}>
                  <h3>3</h3>
                  <Text>
                    Send by email to{" "}
                    <a href="xpress@cancopetroleum.ca">
                      xpress@cancopetroleum.ca
                    </a>
                  </Text>
                </div>
              </div>
              <Anchor href={routes.BECOME_FLEET_CARD_MEMBER}>
                START APPLICATION
              </Anchor>
            </Col>
          </Row>
        </Container>
      </div>

      <Text $textAlign="center" className={styles.applicationSendInfo}>
        You can send your application by mail to 200 -125, Hwy 33 E, Kelowna, BC
        V1X 2A1
        <br />
        For questions or concerns xpress@cancopetroleum.ca or contact Canco Head
        Office: +1 236-420-0688
      </Text>
    </div>
  );
};

export default CancoFleetCard;
