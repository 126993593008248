import { Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { routes } from "./utils/routes";
import { Header, Footer } from "./components/parts";
import {
  Home,
  About,
  Contact,
  FindLocation,
  Login,
  CancoCashLoyalty,
  Register,
  InStore,
  WorkWithUs,
  Business,
  CancoFleetCard,
  ForgotPassword,
  ResetPassword,
  CancoConnect,
  BecomeVendor,
  BecomeDealer,
  Blogs,
  BlogInner,
  BecomeRealtor,
  BecomeFleetCardMember,
  TermsAndCondition,
  EmailConfirmation,
  UserProfile,
  ThankYou,
  Survey,
  RequestAccountDeactivation,
  SuccessAccountDeletionRequest,
  RoadTripRules,
  CancoContest,
  SchoolSpirit,
  MissingPoints,
} from "./screens";
import "./App.scss";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      // Create and append the script element for gtag.js
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`;
      document.head.appendChild(script);

      // Insert the GTM script to configure gtag
      const gtagScript = document.createElement("script");
      gtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GTM_ID}');
      `;
      document.head.appendChild(gtagScript);
    }
  }, []);
  return (
    <AuthProvider>
      <main>
        <Header />
        <Routes>
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.ABOUT} element={<About />} />
          <Route path={routes.CONTACT} element={<Contact />} />
          <Route path={routes.FIND_LOCATION} element={<FindLocation />} />
          <Route path={routes.LOGIN} element={<Login />} />
          <Route
            path={routes.CANCO_CASH_LOYALTY}
            element={<CancoCashLoyalty />}
          />
          <Route path={routes.REGISTER} element={<Register />} />
          <Route path={routes.IN_STORE} element={<InStore />} />
          <Route path={routes.WORK_WITH_US} element={<WorkWithUs />} />
          <Route path={routes.BUSINESS} element={<Business />} />
          <Route path={routes.CANCO_FLEET_CARD} element={<CancoFleetCard />} />
          <Route path={routes.CANCO_CONNECT} element={<CancoConnect />} />
          <Route path={routes.BECOME_VENDOR} element={<BecomeVendor />} />
          <Route path={routes.BECOME_DEALER} element={<BecomeDealer />} />
          <Route path={routes.BECOME_DEALER} element={<BecomeDealer />} />
          <Route path={routes.BECOME_REALTOR} element={<BecomeRealtor />} />
          <Route path={routes.SURVEY} element={<Survey />} />
          <Route path={routes.BECOME_FLEET_CARD_MEMBER} element={<BecomeFleetCardMember />} />
          <Route path={routes.BLOGS} element={<Blogs />} />
          <Route path={routes.EDIT_USER_PROFILE} element={<UserProfile />} />
          <Route path={`/${routes.THANK_YOU}`} element={<ThankYou />} />
          <Route path={routes.SINGLE_BLOG} element={<BlogInner />} />
          <Route
            path={routes.TERMS_CONDITION}
            element={<TermsAndCondition />}
          />
          <Route
            path={routes.EMAIL_CONFIRMATION}
            element={<EmailConfirmation />}
          />
          <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={routes.REQUEST_ACOOUNT_DEACTIVATION}
            element={<RequestAccountDeactivation />}
          />
          <Route
            path={routes.SUCCESS_ACOOUNT_DEACTIVATION}
            element={<SuccessAccountDeletionRequest />}
          />
          <Route path={routes.ROAD_TRIP_RULES} element={<RoadTripRules />} />
          <Route path={routes.CANCO_CONTEST} element={<CancoContest />} />
          <Route path={routes.SCHOOL_SPIRIT} element={<SchoolSpirit />} />
          <Route path={routes.MISSING_POINT} element={<MissingPoints />} />
          <Route path={"/signup"} element={<Navigate to={routes.REGISTER} />} />
          <Route
            path={"/TermsAndPrivacy"}
            element={<Navigate to={routes.TERMS_CONDITION} />}
          />
        </Routes>
        <Footer />
        <a href={routes.SURVEY} className="floatingBtn">
          feedback
        </a>
      </main>
    </AuthProvider>
  );
}

export default App;
