import React from "react";
import { useFormik } from "formik";
import { fleetMemberStepFourInitialValues } from "../../../../../../utils/formik/initialvalue";
import { fleetMemberStepFourValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  SectionTitle,
} from "../../../../../styledComponent";
import styles from "../../style.module.scss";
import { ChevronRight } from "react-bootstrap-icons";

const StepFour = (props) => {
  const formik = useFormik({
    initialValues: fleetMemberStepFourInitialValues,
    validationSchema: fleetMemberStepFourValidationSchema,
    onSubmit: (values) => {
      const formattedDate = formatDate(values.dob);
      props.data({ ...values, dob: formattedDate });
      props.next();
      // console.log({ ...values, dob: formattedDate });
    },
  });

  function formatDate(date) {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  }

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <SectionTitle className={styles.formTitle}>Owner/Officer</SectionTitle>
      <div>
        <div>
          <Label htmlFor="first_name">First Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("first_name")}
            style={
              formik.touched.first_name &&
              formik.errors.first_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.first_name}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="last_name">Last Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("last_name")}
            style={
              formik.touched.last_name &&
              formik.errors.last_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.last_name}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="dob">Date of Birth*</Label>
          <Input
            type="date"
            name="dob"
            {...formik.getFieldProps("dob")}
            // onChange={(e) => {
            //   // const formattedDate = formatDate(e.target.value);
            //   formik.setFieldValue('dob', formattedDate);
            // }}
            // onBlur={formik.handleBlur}
            // {...formik.getFieldProps("dob")}
            style={
              formik.touched.dob &&
              formik.errors.dob && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.dob && formik.errors.dob && (
            <ErrorText className={styles.errorText}>
              {formik.errors.dob}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="street">Street*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("street")}
            style={
              formik.touched.street &&
              formik.errors.street && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.street && formik.errors.street && (
            <ErrorText className={styles.errorText}>
              {formik.errors.street}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="apartment">Apt*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("apartment")}
            style={
              formik.touched.apartment &&
              formik.errors.apartment && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.apartment && formik.errors.apartment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.apartment}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="city">City*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("city")}
            style={
              formik.touched.city &&
              formik.errors.city && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.city && formik.errors.city && (
            <ErrorText className={styles.errorText}>
              {formik.errors.city}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="province">Province*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("province")}
            style={
              formik.touched.province &&
              formik.errors.province && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.province && formik.errors.province && (
            <ErrorText className={styles.errorText}>
              {formik.errors.province}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="postcode">Postal Code*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("postcode")}
            style={
              formik.touched.postcode &&
              formik.errors.postcode && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.postcode && formik.errors.postcode && (
            <ErrorText className={styles.errorText}>
              {formik.errors.postcode}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="sin">SIN*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("sin")}
            style={
              formik.touched.sin &&
              formik.errors.sin && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.sin && formik.errors.sin && (
            <ErrorText className={styles.errorText}>
              {formik.errors.sin}
            </ErrorText>
          )}
        </div>
      </div>
      <div className={styles.formBtnContainer}>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepFour;
