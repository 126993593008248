import React, { useState } from "react";
import { StepOne, StepTwo, StepThree, StepFour, StepFive } from "./steps";

const BecomeFleetCardMemberForm = ({ loader, success }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(null);

  return (
    <>
      {currentStep === 1 ? (
        <StepOne
          next={() => setCurrentStep((prev) => prev + 1)}
          data={(data) => setFormData(data)}
        />
      ) : currentStep === 2 ? (
        <StepTwo
          prev={() => setCurrentStep((prev) => prev - 1)}
          next={() => setCurrentStep((prev) => prev + 1)}
          data={(data) =>
            setFormData((prev) => ({ ...prev, mailing_address: data }))
          }
        />
      ) : currentStep === 3 ? (
        <StepThree
          prev={() => setCurrentStep((prev) => prev - 1)}
          next={() => setCurrentStep((prev) => prev + 1)}
          data={(data) =>
            setFormData((prev) => ({ ...prev, payable_contact_person: data }))
          }
        />
      ) : currentStep === 4 ? (
        <StepFour
          prev={() => setCurrentStep((prev) => prev - 1)}
          next={() => setCurrentStep((prev) => prev + 1)}
          data={(data) =>
            setFormData((prev) => ({ ...prev, owner_detail: data }))
          }
        />
      ) : currentStep === 5 ? (
        <StepFive
          prev={() => setCurrentStep((prev) => prev - 1)}
          prevData={formData}
          isSubmitting={loader}
          isSuccess={success}
        />
      ) : null}
    </>
  );
};

export default BecomeFleetCardMemberForm;
